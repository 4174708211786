import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { GenreBanner } from './GenreBanner';
import { ApiGenreBanner } from '../../api/productGroup';
import { ScrollControl, ScrollDirection } from '../../components/ScrollControl';
import { ScrollItemSize, SideScroll, useSideScroll } from '../../components/Sidescroll';
import { Skippable } from '../../components/Skippable';

interface IImageBannersProps {
    readonly banners: ReadonlyArray<ApiGenreBanner>;
    readonly label: string;
}

export const GenreBanners: FC<IImageBannersProps> = ({ banners, label }) => {
    const { t } = useTranslation();
    const { prev, next, scrollLeftPossible, scrollRightPossible, ...sideScrollProps } = useSideScroll({
        itemSize: ScrollItemSize.GildAuto
    });

    if (banners.length < 1) return null;

    return (
        <section className="item-group genre-banner-group small" aria-label={label}>
            <div className="group-body">
                <Skippable title={t('home.discover.banners.genre.skip_label')}>
                    <SideScroll {...sideScrollProps} className="item-list" itemClassName="list-item">
                        {banners.map((banner, index) => (
                            <div className="item-wrapper" key={`${banner.genreId}-${index}`}>
                                <GenreBanner banner={banner} />
                            </div>
                        ))}
                    </SideScroll>
                    {scrollLeftPossible && <ScrollControl direction={ScrollDirection.left} onClick={prev} />}
                    {scrollRightPossible && <ScrollControl direction={ScrollDirection.right} onClick={next} />}
                </Skippable>
            </div>
        </section>
    );
};
