import Link from 'next/link';
import { FC, memo, useEffect, useState } from 'react';

import { SIZES_TOP_BANNER } from '../../../next-image-sizes';
import { TopBanner as TopBannerType } from '../../api/banners/bannerTypes';
import { pathProductDetails, pathProductDetailsShort, pathProductGroup } from '../../utils/routes/paths';
import { PLACEHOLDER_GENRE_BANNER } from '../../utils/styles/image';
import { NextImage } from '../next/NextImage';

type Props = {
    readonly banner: TopBannerType;
};

export const TopBanner: FC<Props> = props => {
    const { banner } = props;

    return (
        <Link href={topBannerLink(banner)} legacyBehavior>
            <a className="top-banner">
                <TopBannerImage {...props} />
            </a>
        </Link>
    );
};

const TopBannerImage = memo(
    ({ banner: { imageUrl, title } }: Props) => {
        const [priority, setPriority] = useState(false);

        useEffect(() => {
            const isRequestIdleCallbackSupported = 'requestIdleCallback' in window;

            if (!isRequestIdleCallbackSupported) return;

            const idleCallbackId = window.requestIdleCallback(() => {
                setPriority(true);
            });

            return () => cancelIdleCallback(idleCallbackId);
        }, [title]);

        return (
            <NextImage
                src={imageUrl}
                alt={title ?? ''}
                sizes={SIZES_TOP_BANNER}
                quality={90}
                placeholder="blur"
                blurDataURL={PLACEHOLDER_GENRE_BANNER}
                priority={priority}
            />
        );
    },
    (prevProps, nextProps) => prevProps.banner === nextProps.banner
);

function topBannerLink(topBanner: TopBannerType): string {
    const { title } = topBanner;

    if ('productId' in topBanner) {
        const { productId } = topBanner;
        return title ? pathProductDetails({ productId, title }) : pathProductDetailsShort(productId);
    }

    if ('productGroupId' in topBanner) {
        const { productGroupId } = topBanner;
        return pathProductGroup(productGroupId, title ?? '');
    }

    const { link } = topBanner;
    return link ?? '';
}
