import { FC } from 'react';

import { DefaultBanner } from './DefaultBanner';
import { PerformerBanner } from './PerformerBanner';
import { SeriesBanner } from './SeriesBanner';
import { ApiFeaturedBanner, FeaturedBannerStyle } from '../../api/banners/apiFetchFeaturedBanners';

type Props = {
    readonly banner: ApiFeaturedBanner;
};

export const FeaturedBanner: FC<Props> = ({ banner }) => {
    switch (banner.type) {
        case FeaturedBannerStyle.performer:
            return <PerformerBanner banner={banner} />;
        case FeaturedBannerStyle.moreFromSeries:
            return <SeriesBanner banner={banner} />;
        case FeaturedBannerStyle.productTeaser:
        case FeaturedBannerStyle.productQuote:
        case FeaturedBannerStyle.productBestsellers:
        case FeaturedBannerStyle.productDefault:
            return <DefaultBanner banner={banner} />;
        default:
            return null;
    }
};
