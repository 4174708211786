import { BannerList } from './bannerTypes';
import { AgeCategoryType, serializeAgeCategory } from '../../domain/ageCategory';
import { Performer } from '../../domain/performer';
import { Availability } from '../../domain/product';
import { ApiClient } from '../../setup/axios';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { PaginationParams, SiteParams } from '../index';

export type ApiCampaignBanner = {
    readonly authors: ReadonlyArray<Performer>;
    readonly externalId: string;
    readonly loanFormat: LoanFormatType;
    readonly productId: string;
    readonly availability: Availability;
    readonly title: string;
    readonly tagline?: string;
    readonly coverUrl: string;
};

type ApiCampaignBannerRequestParams = SiteParams & {
    readonly pagination?: PaginationParams;
    readonly loanFormat?: LoanFormatType;
    readonly ageCategory?: AgeCategoryType;
};

export const fetchCampaignBanners = (
    client: ApiClient,
    { pagination, loanFormat, ageCategory, ...params }: ApiCampaignBannerRequestParams
): Promise<BannerList<ApiCampaignBanner>> =>
    client
        .get<BannerList<ApiCampaignBanner>>('/api/v1/banner/campaigns', {
            ...params,
            params: { ...pagination, loanFormat, ageCategory: serializeAgeCategory(ageCategory) }
        })
        .then(result => result.data);
