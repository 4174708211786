import classNames from 'classnames';
import { FC, useId } from 'react';

import { BannerCovers } from './BannerCovers';
import { ApiFeaturedBannerDefault, FeaturedBannerStyle } from '../../api/banners/apiFetchFeaturedBanners';
import { MyListButton } from '../../components/MyListButton';
import { ProductActionButton } from '../../components/product-action/ProductActionButton';
import { pathProductDetails } from '../../utils/routes/paths';
import { separateFirstWordsAndLastWord } from '../../utils/string';

export type Props = {
    readonly banner: ApiFeaturedBannerDefault;
};

export const DefaultBanner: FC<Props> = ({ banner }) => {
    const firstProduct = banner.products[0];
    const isQuote = [FeaturedBannerStyle.productTeaser, FeaturedBannerStyle.productQuote].includes(banner.type);
    const isBestseller = !isQuote;

    const { firstWords, lastWord } = separateFirstWordsAndLastWord(banner.quote);
    const titleId = useId();

    return (
        <div className={classNames('featured-product', isQuote ? 'excerpt' : 'bestseller')}>
            {isBestseller && (
                <div className="title" id={titleId}>
                    <span>
                        {firstWords}
                        <span className="strong">{lastWord}</span>
                    </span>
                </div>
            )}
            <BannerCovers products={[firstProduct]} url={pathProductDetails(firstProduct)} />
            {isQuote && (
                <div className="excerpt">
                    <span>{banner.quote}</span>
                </div>
            )}
            <div className="buttons">
                <MyListButton
                    productId={firstProduct.productId}
                    defaultClassNameOverride="my-list"
                    ariaDescribedBy={titleId}
                />
                <ProductActionButton
                    product={{
                        productId: firstProduct.productId,
                        title: firstProduct.title,
                        loanFormat: firstProduct.loanFormat,
                        coverUrl: firstProduct.coverUrl,
                        availabilityStatus: firstProduct.availability.status,
                        availabilityDate: firstProduct.availability.nextAvailableDate,
                        // FIXME: Add following props to banner
                        latestAvailableIssueId: undefined,
                        currentPeriodEnd: undefined,
                        rootProductId: undefined,
                        rootProductTitle: undefined,
                        releaseFrequency: undefined,
                        restrictions: undefined
                    }}
                    look="action"
                    ariaDescribedBy={titleId}
                />
            </div>
        </div>
    );
};
