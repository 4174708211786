import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FeaturedBanner } from './FeaturedBanner';
import { useFeaturedBanners } from '../../api/banners/useFeaturedBanners';
import { ScrollControl, ScrollDirection } from '../../components/ScrollControl';
import { ScrollItemSize, SideScroll, useSideScroll } from '../../components/Sidescroll';
import { Skippable } from '../../components/Skippable';
import { useScopeContext } from '../../context/ScopeContext';
import { isEmpty } from '../../utils/objectChecks';

export const FeaturedBanners: FC = () => {
    const { t } = useTranslation();
    const { prev, next, scrollLeftPossible, scrollRightPossible, ...sideScrollProps } = useSideScroll({
        itemSize: ScrollItemSize.GildAuto
    });

    const scope = useScopeContext();
    const banners = useFeaturedBanners(scope);

    if (!banners || isEmpty(banners)) return null;

    return (
        <div
            className={classNames('featured-product-group', 'item-group')}
            aria-label={t('home.discover.banners.featured.label')}
            data-testid="featured-banners"
        >
            <div className="group-body">
                <Skippable title={t('home.discover.banners.featured.skip_label')}>
                    <SideScroll {...sideScrollProps} className="item-list" itemClassName="list-item">
                        {banners.items.map((banner, index) => (
                            <div className="item-wrapper" key={index}>
                                <FeaturedBanner banner={banner} />
                            </div>
                        ))}
                    </SideScroll>
                    {scrollLeftPossible ? <ScrollControl direction={ScrollDirection.left} onClick={prev} /> : null}
                    {scrollRightPossible ? <ScrollControl direction={ScrollDirection.right} onClick={next} /> : null}
                </Skippable>
            </div>
        </div>
    );
};
