import { FC } from 'react';

import { ProductGroup } from './ProductGroup';
import { ProductGroupProductsParams, useProductGroupProducts } from '../api/product-group/useProductGroupProducts';
import { ProductGroupPageSlotResponse } from '../api/productGroup';
import { AgeCategoryType } from '../domain/ageCategory';
import { useProductGroupSeeAllLink } from '../hooks/getters/useProductGroupSeeAllLink';
import { getProductGroupFetchParams } from '../hooks/getters/useProductListParams';
import { aggregateTotalCount, LoanFormatType } from '../utils/domain/loanFormat';

type PageProductGroupProps = {
    readonly categoryTitle?: string;
    readonly data: ProductGroupPageSlotResponse;
    readonly loanFormat?: LoanFormatType;
    readonly ageCategory?: AgeCategoryType;
};

// Must be one item more than maximum item count in product groups on huge screens.
// Otherwise, Browser render bugs can occur, randomly rendering the `SideScroller` in an initially scrolled state.
const LIMIT_AT_RENDER = 40;

export const DEFAULT_PRODUCT_GROUP_PARAMS: ProductGroupProductsParams = {
    availableOnly: false,
    offset: 0,
    limit: LIMIT_AT_RENDER,
    filters: {}
};

export const PageProductGroup: FC<PageProductGroupProps> = ({ categoryTitle, data, loanFormat, ageCategory }) => {
    const seeAllUri = useProductGroupSeeAllLink({ productGroupId: data.productGroupId, productGroupName: data.title });

    const {
        data: { products, totalCount: totalCountFromQuery },
        isLoading: productsLoading
    } = useProductGroupProducts({
        productGroupId: data.productGroupId,
        params: getProductGroupFetchParams({
            params: DEFAULT_PRODUCT_GROUP_PARAMS,
            loanFormat,
            ageCategory
        }),
        // Prevent scroll reset while new products are loaded when limit changes.
        keepPreviousData: true
    });
    const totalCount = aggregateTotalCount(productsLoading ? data.totalCount : totalCountFromQuery);

    return (
        <ProductGroup
            categoryTitle={categoryTitle}
            title={data.title}
            totalCount={totalCount}
            products={products}
            seeAllUri={seeAllUri}
        />
    );
};
