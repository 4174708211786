import { ApiCampaignBanner } from './banners/apiFetchCampaignBanners';
import { ApiFeaturedBanners } from './banners/apiFetchFeaturedBanners';
import { TopBanner } from './banners/bannerTypes';
import { GenreId } from './genres';

export enum SlotType {
    heading = 'HEADING',
    productGroup = 'PRODUCT_GROUP',
    banner = 'BANNER'
}

export type ApiGenreBanner = {
    readonly imageUrl: string;
    readonly genreId: GenreId;
    readonly genreName: string;
};

export interface TopBannersPageSlotResponse {
    readonly slotType: SlotType.banner;
    readonly bannerSlotType: 'TOP_BANNER';
    readonly banners: ReadonlyArray<TopBanner>;
}

interface CampaignBannersPageSlotResponse {
    readonly slotType: SlotType.banner;
    readonly bannerSlotType: 'CAMPAIGN_BANNER';
    readonly banners?: ReadonlyArray<ApiCampaignBanner>;
    readonly totalCount: number;
}

interface FeaturedBannersPageSlotResponse {
    readonly slotType: SlotType.banner;
    readonly bannerSlotType: 'FEATURED_BANNER';
    readonly banners?: ReadonlyArray<ApiFeaturedBanners>;
}

export interface GenreBannersPageSlotResponse {
    readonly slotType: SlotType.banner;
    readonly bannerSlotType: 'GENRE_BANNER';
    readonly banners: ReadonlyArray<ApiGenreBanner>;
}

interface CuratedCollectionBannersPageSlotResponse {
    readonly slotType: SlotType.banner;
    readonly bannerSlotType: 'CURATED_COLLECTION_BANNER';
}

export interface HeadingPageSlotResponse {
    readonly slotType: SlotType.heading;
    readonly title: string;
}

export interface ProductGroupPageSlotResponse {
    readonly slotType: SlotType.productGroup;
    readonly productGroupId: number;
    readonly title: string;
    readonly totalCount: {
        eaudiobooks: number;
        ebooks: number;
        emagazines: number;
        emagazineissues: number;
    };
}

export type BannersPageSlotResponse =
    | TopBannersPageSlotResponse
    | CampaignBannersPageSlotResponse
    | FeaturedBannersPageSlotResponse
    | GenreBannersPageSlotResponse
    | CuratedCollectionBannersPageSlotResponse;

export type PageSlotResponse = BannersPageSlotResponse | HeadingPageSlotResponse | ProductGroupPageSlotResponse;

export type PageSlotsResponse = ReadonlyArray<PageSlotResponse>;
