import { QueryClient, useQuery } from '@tanstack/react-query';

import { fetchFeaturedBanners } from './apiFetchFeaturedBanners';
import { AgeCategoryType } from '../../domain/ageCategory';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { ApiClient, browserClient } from '../../setup/axios';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { SiteParams } from '../index';

const QUERY_KEY = 'featuredBanners';

type QueryParams = {
    readonly loanFormat?: LoanFormatType;
    readonly ageCategory?: AgeCategoryType;
};

type Options = QueryParams & {
    readonly fetchFunc?: typeof fetchFeaturedBanners;
};

type BuildQueryKeyParams = {
    readonly siteId?: string;
    readonly loanFormat?: LoanFormatType;
    readonly ageCategory?: AgeCategoryType;
};

const buildQueryKey = ({ siteId, loanFormat, ageCategory }: BuildQueryKeyParams) => {
    return [QUERY_KEY, { siteId, loanFormat, ageCategory }];
};

export const useFeaturedBanners = ({ loanFormat, ageCategory }: Options) => {
    const { siteId, language } = useSiteParams();

    const { data } = useQuery({
        queryKey: buildQueryKey({ siteId, loanFormat, ageCategory }),
        queryFn: () =>
            fetchFeaturedBanners(browserClient, {
                loanFormat,
                ageCategory,
                siteId,
                language
            })
    });

    return data;
};

export const prefetchFeaturedBanners = (
    apiClient: ApiClient,
    queryClient: QueryClient,
    { loanFormat, ageCategory }: Options,
    { siteId, language }: SiteParams
) => {
    return queryClient.fetchQuery({
        queryKey: buildQueryKey({ siteId, loanFormat, ageCategory }),
        queryFn: () =>
            fetchFeaturedBanners(apiClient, {
                loanFormat,
                ageCategory,
                siteId,
                language
            })
    });
};
