import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CampaignBanner } from './CampaignBanner';
import { useCampaignBanners } from '../../api/banners/useCampaignBanners';
import { ScrollControl, ScrollDirection } from '../../components/ScrollControl';
import { ScrollItemSize, SideScroll, useSideScroll } from '../../components/Sidescroll';
import { Skippable } from '../../components/Skippable';
import { useScopeContext } from '../../context/ScopeContext';

type CampaignBannersProps = {
    readonly className?: string;
};

export const CampaignBanners: FC<CampaignBannersProps> = ({ className }) => {
    const { t } = useTranslation();
    const { data: banners } = useCampaignBanners(useScopeContext());

    const { prev, next, scrollLeftPossible, scrollRightPossible, ...sideScrollProps } = useSideScroll({
        step: 3,
        itemSize: ScrollItemSize.GildAuto
    });

    if (!banners || !banners.totalCount || banners.totalCount < 1) return null;

    return (
        <div
            className={classNames(className, 'item-group', 'campaign-banner-group')}
            aria-label={t('home.discover.banners.campaigns.label')}
        >
            <div className="group-body">
                <Skippable title={t('home.discover.banners.campaigns.skip_label')}>
                    <SideScroll {...sideScrollProps} className="item-list" itemClassName="list-item">
                        {banners.items.map(banner => (
                            <div className="item-wrapper" key={banner.productId}>
                                <CampaignBanner banner={banner} />
                            </div>
                        ))}
                    </SideScroll>
                    {scrollLeftPossible ? <ScrollControl direction={ScrollDirection.left} onClick={prev} /> : null}
                    {scrollRightPossible ? <ScrollControl direction={ScrollDirection.right} onClick={next} /> : null}
                </Skippable>
            </div>
        </div>
    );
};
