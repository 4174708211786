import { QueryClient, useQuery } from '@tanstack/react-query';

import { ApiCampaignBanner, fetchCampaignBanners } from './apiFetchCampaignBanners';
import { BannerList } from './bannerTypes';
import { AgeCategoryType } from '../../domain/ageCategory';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { ApiClient, browserClient } from '../../setup/axios';
import { UseQueryResult } from '../../setup/react-query/queryTypes';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { PaginationParams, SiteParams } from '../index';

const QUERY_KEY = 'campaignBanners';
type QueryParams = {
    readonly pagination?: PaginationParams;
    readonly loanFormat?: LoanFormatType;
    readonly ageCategory?: AgeCategoryType;
};
type Options = QueryParams & {
    readonly fetchFunc?: typeof fetchCampaignBanners;
};

type UseCampaignBanners = BannerList<ApiCampaignBanner>;

const EMPTY_CAMPAIGN_BANNERS: UseCampaignBanners = {
    items: [],
    totalCount: 0
};

type BuildQueryKeyParams = {
    readonly siteId?: string;
    readonly loanFormat?: LoanFormatType;
    readonly ageCategory?: AgeCategoryType;
};

const buildQueryKey = ({ siteId, loanFormat, ageCategory }: BuildQueryKeyParams) => {
    return [QUERY_KEY, { siteId, loanFormat, ageCategory }];
};

export const useCampaignBanners = ({
    loanFormat,
    ageCategory,
    fetchFunc = fetchCampaignBanners
}: Options): UseQueryResult<'data', UseCampaignBanners> => {
    const { siteId, language } = useSiteParams();

    const { data, ...rest } = useQuery({
        queryKey: buildQueryKey({ siteId, loanFormat, ageCategory }),
        queryFn: () =>
            fetchFunc(browserClient, {
                loanFormat,
                ageCategory,
                siteId,
                language
            })
    });

    return {
        data: data ?? EMPTY_CAMPAIGN_BANNERS,
        ...rest
    };
};
export const prefetchCampaignBanners = (
    apiClient: ApiClient,
    queryClient: QueryClient,
    { pagination, loanFormat, ageCategory, fetchFunc = fetchCampaignBanners }: Options,
    { siteId, language }: SiteParams
) => {
    return queryClient.fetchQuery({
        queryKey: buildQueryKey({ siteId, loanFormat, ageCategory }),
        queryFn: () =>
            fetchFunc(apiClient, {
                pagination,
                loanFormat,
                ageCategory,
                siteId,
                language
            })
    });
};
