import { ApiProductGroupId } from '../../api/product-group/apiFetchProductGroupProducts';
import { pathProductGroup } from '../../utils/routes/paths';
import { useScopedPath } from '../utils/useScopedPath';

interface Options {
    productGroupId: ApiProductGroupId;
    productGroupName: string;
}

export function useProductGroupSeeAllLink({ productGroupId, productGroupName }: Options): string {
    return useScopedPath(pathProductGroup(productGroupId, productGroupName));
}
