import { serializeAgeCategory } from '../../domain/ageCategory';
import { ShortProduct } from '../../domain/product';
import { ApiClient } from '../../setup/axios';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { SiteParams, TotalCountByLoanFormat } from '../index';
import { ApiProductsRequest, ApiProductsRequestParams } from '../products';
import { ApiSortOptionsResponse } from '../sortOptions';

export type ApiProductGroupId = number;

export type ApiProductGroupProductsResponse = {
    readonly title: string;
    readonly totalCount: TotalCountByLoanFormat;
    readonly products: ReadonlyArray<ShortProduct>;
    readonly sortOptions: ApiSortOptionsResponse;
};

export const EMPTY_PRODUCT_GROUP_PRODUCTS_RESPONSE: ApiProductGroupProductsResponse = {
    title: '',
    totalCount: {
        [LoanFormatType.ebooks]: 0,
        [LoanFormatType.eaudiobooks]: 0,
        [LoanFormatType.eMagazineIssues]: 0,
        [LoanFormatType.eMagazines]: 0
    },
    products: [],
    sortOptions: {
        options: []
    }
};

export function fetchProductGroupProducts(
    client: ApiClient,
    productGroupId: ApiProductGroupId,
    body: ApiProductsRequest,
    { siteId, language, ...params }: ApiProductsRequestParams & SiteParams
): Promise<ApiProductGroupProductsResponse> {
    return client
        .post<ApiProductGroupProductsResponse>(`/api/v1/productgroups/${productGroupId}/products`, body, {
            siteId,
            language,
            params: {
                ...params,
                ageCategory: serializeAgeCategory(params.ageCategory)
            }
        })
        .then(res => res.data)
        .catch(() => Promise.resolve(EMPTY_PRODUCT_GROUP_PRODUCTS_RESPONSE));
}
