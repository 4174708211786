import Link from 'next/link';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BannerCovers } from './BannerCovers';
import { ApiFeaturedBannerSeries } from '../../api/banners/apiFetchFeaturedBanners';
import { Icon } from '../../components/Icon';
import { pathSeries } from '../../utils/routes/paths';

type Props = {
    readonly banner: ApiFeaturedBannerSeries;
};

export const SeriesBanner: FC<Props> = ({ banner: { products, performer, seriesName, seriesId } }) => {
    const { t } = useTranslation();
    const url = seriesId ? pathSeries(seriesId, seriesName) : '#';

    return (
        <div className="featured-product series">
            <div className="title">
                <span>
                    {performer.name} –<br />
                    <span className="strong">{seriesName}</span>
                </span>
            </div>
            <BannerCovers products={products} url={url} />
            {seriesId && (
                <div className="buttons">
                    <Link href={url} className="action no-libcolor-text-on-link-hover">
                        <span>
                            {t('defaults.button.view_complete_series')}
                            <Icon icon="arrow-forward" />
                        </span>
                    </Link>
                </div>
            )}
        </div>
    );
};
