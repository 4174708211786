import classNames from 'classnames';
import Link from 'next/link';
import { FC, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { SIZES_BANNER_COVER } from '../../../next-image-sizes';
import { ApiCampaignBanner } from '../../api/banners/apiFetchCampaignBanners';
import { MyListButton } from '../../components/MyListButton';
import { NextImage } from '../../components/next/NextImage';
import { ProductActionButton } from '../../components/product-action/ProductActionButton';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { pathProductDetails } from '../../utils/routes/paths';
import { separateFirstWordsAndLastWord } from '../../utils/string';
import { getPlaceholderCoverByFormat } from '../../utils/styles/image';

type CampaignBannerProps = {
    readonly banner: ApiCampaignBanner;
};

export const CampaignBanner: FC<CampaignBannerProps> = ({ banner }) => {
    const { t } = useTranslation();

    const placeholderCover = getPlaceholderCoverByFormat(banner.loanFormat);

    const titleFallback =
        banner.loanFormat === LoanFormatType.eaudiobooks
            ? t('home.discover.banners.campaigns.title_fallback_listen')
            : t('home.discover.banners.campaigns.title_fallback_read');

    const { title, excerpt } = getTitleAndExcerpt(banner.tagline, titleFallback);
    const { firstWords, lastWord } = separateFirstWordsAndLastWord(title);

    const titleId = useId();

    return (
        <div className={classNames('campaign-banner', excerpt !== undefined ? 'excerpt' : 'bestseller')}>
            {title !== undefined ? (
                <div className="title" id={titleId}>
                    <span>
                        {firstWords} <span className="strong">{lastWord}</span>
                    </span>
                </div>
            ) : null}
            <div className="covers" data-totalcovers="1">
                <div className="all-covers-wrapper">
                    <Link href={pathProductDetails(banner)}>
                        <div className="cover-wrapper" data-covernumber="0">
                            <NextImage
                                src={banner.coverUrl}
                                alt={banner.title}
                                sizes={SIZES_BANNER_COVER}
                                placeholder="blur"
                                blurDataURL={placeholderCover}
                            />
                        </div>
                    </Link>
                </div>
            </div>
            {excerpt !== undefined ? (
                <div className="excerpt" id={titleId}>
                    <span>{excerpt}</span>
                </div>
            ) : null}
            <div className="buttons">
                <MyListButton productId={banner.productId} ariaDescribedBy={titleId} />
                <ProductActionButton
                    product={{
                        productId: banner.productId,
                        title: banner.title,
                        authors: banner.authors,
                        loanFormat: banner.loanFormat,
                        coverUrl: banner.coverUrl,
                        availabilityStatus: banner.availability.status,
                        availabilityDate: banner.availability.nextAvailableDate,
                        latestAvailableIssueId: banner.availability.latestAvailableIssueId,
                        // FIXME: Add following props to banner
                        currentPeriodEnd: undefined,
                        rootProductId: undefined,
                        rootProductTitle: undefined,
                        releaseFrequency: undefined,
                        restrictions: undefined
                    }}
                    look="filled"
                    ariaDescribedBy={titleId}
                />
            </div>
        </div>
    );
};

function getTitleAndExcerpt(tagline: string | undefined, titleFallback: string): { title?: string; excerpt?: string } {
    if (!tagline) {
        return { title: titleFallback };
    }

    if (isVerboseTagline(tagline)) {
        return { excerpt: tagline };
    }

    return { title: tagline };
}

function isVerboseTagline(tagline?: string): boolean {
    const numberOfWords = tagline ? tagline.split(' ').length : 0;
    return numberOfWords > 4;
}
