import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CampaignBanners } from './CampaignBanners';
import { FeaturedBanners } from './FeaturedBanners';
import {
    BannersPageSlotResponse,
    GenreBannersPageSlotResponse,
    PageSlotResponse,
    ProductGroupPageSlotResponse,
    SlotType,
    TopBannersPageSlotResponse
} from '../../api/productGroup';
import { PageProductGroup } from '../../components/PageProductGroup';
import { TopBanners } from '../../components/top-banner/TopBanners';
import { useScopeContext } from '../../context/ScopeContext';
import LoggingUtils, { LoggingEnvironment } from '../../utils/logging/LoggingUtils';
import { GenreBanners } from '../genres/GenreBanners';

type PageSlotProps = {
    readonly data: PageSlotResponse;
};

export const PageSlot: FC<PageSlotProps> = ({ data }) => {
    switch (data.slotType) {
        case SlotType.productGroup:
            return <ProductGroupPageSlot data={data} />;
        case SlotType.banner:
            return <BannersPageSlot data={data} />;
        default:
            LoggingUtils.logWarningWithPayload('unsupported slot type', data, [LoggingEnvironment.DEV]);
            return null;
    }
};

type ProductGroupPageSlotProps = {
    readonly data: ProductGroupPageSlotResponse;
};

const ProductGroupPageSlot: FC<ProductGroupPageSlotProps> = ({ data }) => {
    const { loanFormat, ageCategory } = useScopeContext();

    return <PageProductGroup data={data} loanFormat={loanFormat} ageCategory={ageCategory} />;
};

type BannersPageSlotProps = {
    readonly data: BannersPageSlotResponse;
};

const BannersPageSlot: FC<BannersPageSlotProps> = ({ data }) => {
    switch (data.bannerSlotType) {
        case 'TOP_BANNER':
            return <TopBannersPageSlot data={data} />;
        case 'CAMPAIGN_BANNER':
            return <CampaignBanners />;
        case 'FEATURED_BANNER':
            return <FeaturedBanners />;
        case 'GENRE_BANNER':
            return <GenreBannersPageSlot data={data} />;
        case 'CURATED_COLLECTION_BANNER':
            // Not yet implemented
            return null;
        default:
            LoggingUtils.logWarningWithPayload('unsupported banner slot type', data, [LoggingEnvironment.DEV]);
            return null;
    }
};

type TopBannersPageSlotProps = {
    readonly data: TopBannersPageSlotResponse;
};

const TopBannersPageSlot: FC<TopBannersPageSlotProps> = ({ data }) => {
    const { t } = useTranslation();
    return <TopBanners banners={data.banners} label={t('home.discover.banners.top.label')} />;
};

type GenreBannersPageSlotProps = {
    readonly data: GenreBannersPageSlotResponse;
};

const GenreBannersPageSlot: FC<GenreBannersPageSlotProps> = ({ data }) => {
    const { t } = useTranslation();
    return <GenreBanners banners={data.banners} label={t('defaults.banners.genre.label')} />;
};
