import Link from 'next/link';
import { FC } from 'react';

import { SIZES_BANNER_COVER } from '../../../next-image-sizes';
import { ApiFeaturedBannerBaseProduct } from '../../api/banners/apiFetchFeaturedBanners';
import { NextImage } from '../../components/next/NextImage';
import { getPlaceholderCoverByFormat } from '../../utils/styles/image';

type Props = {
    readonly products: ReadonlyArray<ApiFeaturedBannerBaseProduct>;
    readonly url: string;
};

export const BannerCovers: FC<Props> = ({ products, url }) => {
    // use 3 products at most, since we can't visually deal with more
    const covers = products.slice(0, 3);
    return (
        <div className="covers" data-totalcovers={covers.length}>
            <div className="all-covers-wrapper">
                {covers.map((product, index) => {
                    const placeholderCover = getPlaceholderCoverByFormat(product.loanFormat);
                    return (
                        <Link href={url} key={product.productId}>
                            <div className="cover-wrapper" data-covernumber={index}>
                                <NextImage
                                    src={product.coverUrl}
                                    alt={product.title}
                                    sizes={SIZES_BANNER_COVER}
                                    placeholder="blur"
                                    blurDataURL={placeholderCover}
                                />
                            </div>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};
