import { FC } from 'react';

import { GenreListPageLink } from './GenreListPageLink';
import { SIZES_GENRE_BANNER } from '../../../next-image-sizes';
import { ApiGenreBanner } from '../../api/productGroup';
import { NextImage } from '../../components/next/NextImage';
import { PLACEHOLDER_GENRE_BANNER } from '../../utils/styles/image';

type GenreBannerProps = {
    readonly banner: ApiGenreBanner;
};

export const GenreBanner: FC<GenreBannerProps> = ({ banner }) => (
    <div className="genre-banner">
        <GenreListPageLink genreId={banner.genreId} genreName={banner.genreName}>
            <NextImage
                src={banner.imageUrl}
                alt={banner.genreName}
                priority
                sizes={SIZES_GENRE_BANNER}
                placeholder="blur"
                blurDataURL={PLACEHOLDER_GENRE_BANNER}
            />
        </GenreListPageLink>
    </div>
);
