import Link from 'next/link';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BannerCovers } from './BannerCovers';
import { SIZES_AUTHOR_BANNER } from '../../../next-image-sizes';
import { ApiFeaturedBannerAuthor } from '../../api/banners/apiFetchFeaturedBanners';
import { Icon } from '../../components/Icon';
import { NextImage } from '../../components/next/NextImage';
import { pathPerformer } from '../../utils/routes/paths';
import { PLACEHOLDER_SQUARED } from '../../utils/styles/image';

type Props = {
    readonly banner: ApiFeaturedBannerAuthor;
};

export const PerformerBanner: FC<Props> = ({ banner: { performer, products } }) => {
    const { t } = useTranslation();
    const url = pathPerformer(performer.id, performer.name);

    return (
        <div className="featured-product author">
            <div className="author">
                <div className="photo">
                    <Link href={url} legacyBehavior>
                        <a className="action no-libcolor-text-on-link-hover">
                            <NextImage
                                src={performer.photo}
                                alt={performer.name}
                                sizes={SIZES_AUTHOR_BANNER}
                                placeholder="blur"
                                blurDataURL={PLACEHOLDER_SQUARED}
                            />
                        </a>
                    </Link>
                </div>
                <div className="name">
                    <span>
                        {performer.firstName}
                        <span>{performer.lastName}</span>
                    </span>
                </div>
            </div>
            <BannerCovers products={products} url={url} />
            {performer.id ? (
                <div className="buttons">
                    <Link href={url} className="action no-libcolor-text-on-link-hover">
                        <span>
                            {t('defaults.button.view_complete_list')}
                            <Icon icon="arrow-forward" />
                        </span>
                    </Link>
                </div>
            ) : null}
        </div>
    );
};
