import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { PageSlot } from './PageSlot';
import { PageSlotResponse, PageSlotsResponse, SlotType } from '../../api/productGroup';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { isPresent } from '../../utils/objectChecks';

type FeaturedPageProps = {
    readonly slots: PageSlotsResponse;
    readonly loanFormat?: LoanFormatType;
};

export const FeaturedPage: FC<FeaturedPageProps> = ({ slots, loanFormat }) => {
    const { t } = useTranslation();

    const format = loanFormat as 'eBooks' | 'eAudiobooks' | 'eMagazines';
    const mainLabel = loanFormat ? t(`featured_page.main_label.${format}`) : t('home.discover.main_label');

    return (
        <main aria-label={mainLabel}>
            {groupSlots(slots).map(({ slots, Wrapper }, index) => (
                <Wrapper key={index}>
                    {slots.map((slot, slotIndex) =>
                        Wrapper === ContainerWrapper ? (
                            <section key={slotIndex} className="page-section">
                                <PageSlot data={slot} />
                            </section>
                        ) : (
                            <PageSlot key={slotIndex} data={slot} />
                        )
                    )}
                </Wrapper>
            ))}
        </main>
    );
};

type Wrapper = FC<PropsWithChildren>;

const NoWrapper: Wrapper = ({ children }) => <>{children}</>;
const ContainerWrapper: Wrapper = ({ children }) => <div className="container">{children}</div>;
// The CampaignBannerWrapper creates the same html as the ContainerWrapper, BUT:
// with the current logic we use the React-Component to decide where to put slots in the page, so we cannot just use
// the ContainerWrapper
const CampaignBannerWrapper: Wrapper = ({ children }) => <div className="container">{children}</div>;

type SlotGroup = {
    readonly slots: Array<PageSlotResponse>;
    readonly Wrapper: Wrapper;
};

const getWrapper = (slot: PageSlotResponse): Wrapper => {
    if (slot.slotType === SlotType.banner) {
        if (slot.bannerSlotType === 'TOP_BANNER') {
            return NoWrapper;
        }
        if (slot.bannerSlotType === 'CAMPAIGN_BANNER') {
            return CampaignBannerWrapper;
        }
    }

    return ContainerWrapper;
};

const groupSlots = (slots: PageSlotsResponse): Array<SlotGroup> => {
    const groups: Array<SlotGroup> = [];
    const getGroup = (Wrapper: Wrapper): SlotGroup => {
        if (isPresent(groups)) {
            const current = groups[groups.length - 1];
            if (current.Wrapper === Wrapper) return current;
        }

        const container = { slots: [], Wrapper };
        groups.push(container);
        return container;
    };

    for (const slot of slots) {
        const wrapper = getWrapper(slot);
        const container = getGroup(wrapper);
        container.slots.push(slot);
    }

    return groups;
};
