import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TopBanner } from './TopBanner';
import { TopBanner as TopBannerType } from '../../api/banners/bannerTypes';
import { ScrollControl, ScrollDirection } from '../ScrollControl';
import { ScrollItemSize, SideScroll, useSideScroll } from '../Sidescroll';
import { Skippable } from '../Skippable';

interface ITopBannersProps {
    readonly banners: ReadonlyArray<TopBannerType>;
    readonly className?: string;
    readonly label: string;
}

export const TopBanners: FC<ITopBannersProps> = ({ banners, className, label }) => {
    const { t } = useTranslation();
    const { prev, next, scrollLeftPossible, scrollRightPossible, ...sideScrollProps } = useSideScroll({
        itemSize: ScrollItemSize.Centered
    });

    if (banners.length < 1) return null;

    return (
        <div className={classNames(className, 'top-banners')} role="group" aria-label={label}>
            <Skippable title={t('home.discover.banners.top.skip_label')}>
                <SideScroll className="top-banners-slider" {...sideScrollProps}>
                    {banners.map((banner, index) => (
                        <TopBanner key={`${banner.uuid}-${index}`} banner={banner} />
                    ))}
                </SideScroll>
                {scrollLeftPossible && <ScrollControl direction={ScrollDirection.left} onClick={prev} />}
                {scrollRightPossible && <ScrollControl direction={ScrollDirection.right} onClick={next} />}
            </Skippable>
        </div>
    );
};
