import { AgeCategoryType, serializeAgeCategory } from '../../domain/ageCategory';
import { AvailabilityStatus } from '../../domain/product';
import { ApiClient } from '../../setup/axios';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { PaginationParams, SiteParams } from '../index';

export enum FeaturedBannerStyle {
    moreFromSeries = 'SERIES',
    performer = 'AUTHOR',
    productDefault = 'PRODUCT',
    productTeaser = 'TEASER',
    productQuote = 'QUOTE',
    productBestsellers = 'BESTSELLERS'
}

type ApiFeatureBannerRequestParams = SiteParams & {
    readonly pagination?: PaginationParams;
    readonly loanFormat?: LoanFormatType;
    readonly ageCategory?: AgeCategoryType;
};

export type ApiFeaturedBannerBaseProduct = {
    readonly productId: string;
    readonly coverUrl: string;
    readonly title: string;
    readonly loanFormat: LoanFormatType;
};

type ApiFeaturedBannerDefaultProduct = ApiFeaturedBannerBaseProduct & {
    readonly availability: {
        readonly nextAvailableDate?: number;
        readonly status: AvailabilityStatus;
    };
};

export type ApiFeaturedBannerDefault = {
    readonly products: ReadonlyArray<ApiFeaturedBannerDefaultProduct>;
    readonly quote: string;
    readonly type:
        | FeaturedBannerStyle.productDefault
        | FeaturedBannerStyle.productTeaser
        | FeaturedBannerStyle.productQuote
        | FeaturedBannerStyle.productBestsellers;
};

export type ApiFeaturedBannerSeries = {
    readonly products: ReadonlyArray<ApiFeaturedBannerBaseProduct>;
    readonly seriesId: string;
    readonly seriesName: string;
    readonly performer: {
        readonly name: string;
    };
    readonly type: FeaturedBannerStyle.moreFromSeries;
};

export type ApiFeaturedBannerAuthor = {
    readonly products: ReadonlyArray<ApiFeaturedBannerBaseProduct>;
    readonly performer: {
        readonly id: string;
        readonly name: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly photo: string;
    };
    readonly type: FeaturedBannerStyle.performer;
};

export type ApiFeaturedBanner = ApiFeaturedBannerDefault | ApiFeaturedBannerSeries | ApiFeaturedBannerAuthor;

export type ApiFeaturedBanners = { items: ReadonlyArray<ApiFeaturedBanner> };

export const fetchFeaturedBanners = (
    client: ApiClient,
    { pagination, loanFormat, ageCategory, ...params }: ApiFeatureBannerRequestParams
): Promise<ApiFeaturedBanners> => {
    return client
        .get<ApiFeaturedBanners>('/api/v2/banner/featured', {
            ...params,
            params: { ...pagination, loanFormat, ageCategory: serializeAgeCategory(ageCategory) }
        })
        .then(result => result.data);
};
