import { keepPreviousData as queryKeepPreviousData, QueryClient, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import {
    ApiProductGroupId,
    ApiProductGroupProductsResponse,
    EMPTY_PRODUCT_GROUP_PRODUCTS_RESPONSE,
    fetchProductGroupProducts
} from './apiFetchProductGroupProducts';
import { ProductFilterParams, toProductRequestFilters } from '../../hooks/getters/useProductListParams';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { ApiClient, browserClient } from '../../setup/axios';
import { UseQueryResult } from '../../setup/react-query/queryTypes';
import { SiteParams } from '../index';
import { ApiProductsRequestParams } from '../products';

const QUERY_KEY = 'productGroupProducts';

export type ProductGroupProductsParams = ApiProductsRequestParams & {
    readonly filters: ProductFilterParams;
};

type Options = {
    readonly productGroupId: ApiProductGroupId;
    readonly params: ProductGroupProductsParams;
    readonly fetchFunc?: typeof fetchProductGroupProducts;
    readonly keepPreviousData?: boolean;
};

type BuildQueryKeyParams = {
    readonly productGroupId: ApiProductGroupId;
    readonly params: ProductGroupProductsParams;
    readonly siteId: string | undefined | null;
};

const buildQueryKey = ({ productGroupId, params, siteId }: BuildQueryKeyParams) => {
    return [QUERY_KEY, { productGroupId, params, siteId }];
};

export function useProductGroupProducts({
    productGroupId,
    fetchFunc = fetchProductGroupProducts,
    params,
    keepPreviousData = false
}: Options): UseQueryResult<'data', ApiProductGroupProductsResponse> {
    const { siteId, language } = useSiteParams();
    const effectiveFilters = useMemo(() => toProductRequestFilters(params.filters), [params.filters]);

    const { data, ...rest } = useQuery({
        queryKey: buildQueryKey({ productGroupId, params, siteId }),
        queryFn: () =>
            fetchFunc(
                browserClient,
                productGroupId,
                { filters: effectiveFilters },
                {
                    loanFormat: params.loanFormat,
                    availableOnly: params.availableOnly,
                    ageCategory: params.ageCategory,
                    offset: params.offset,
                    limit: params.limit,
                    sortBy: params.sortBy,
                    siteId,
                    language
                }
            ),
        placeholderData: keepPreviousData ? queryKeepPreviousData : undefined
    });

    return {
        data: data ?? EMPTY_PRODUCT_GROUP_PRODUCTS_RESPONSE,
        ...rest
    };
}

export const prefetchProductGroupProducts = (
    client: ApiClient,
    queryClient: QueryClient,
    { productGroupId, fetchFunc = fetchProductGroupProducts, params }: Options,
    { siteId, language }: SiteParams
) => {
    const effectiveFilters = toProductRequestFilters(params.filters);

    return queryClient.fetchQuery({
        queryKey: buildQueryKey({ productGroupId, params, siteId }),
        queryFn: () =>
            fetchFunc(
                client,
                productGroupId,
                { filters: effectiveFilters },
                {
                    loanFormat: params.loanFormat,
                    availableOnly: params.availableOnly,
                    ageCategory: params.ageCategory,
                    offset: params.offset,
                    limit: params.limit,
                    sortBy: params.sortBy,
                    siteId,
                    language
                }
            )
    });
};
